<template>
  <div>
    <c-spinner
      v-if="isLoading"
      size="lg"
    />
    <div
      class="form"
      v-else
    >
      <div class="section-controls">
        <banner-preview
          class="form-control"
          :img="img_desktop"
        />
        <c-input
          class="input form-control"
          required
          label="Nome"
          placeholder="Nome do Banner"
          type="text"
          name="name"
          v-validate="'required'"
          :flex="true"
          :value="mediaData.name"
          :feedback-show="errors.has('name')"
          :feedback-message="errors.first('name')"
          @input="v => mediaData.name = v"
        />
        <div class="form-inline">
          <c-upload-image
            class="input form-control -inline"
            type="file"
            file-accept="image/*"
            file-label="Upload de arquivo"
            label="Banner Desktop (1928X260)"
            v-validate="'required'"
            v-model="mediaData.image"
            :feedback-show="errors.has('desktop')"
            :feedback-message="errors.first('desktop')"
            :name="'desktop'"
            @changed-image="changedImage($event)"
          />

          <c-upload-image
            class="input form-control -inline"
            type="file"
            file-accept="image/*"
            file-label="Upload de arquivo"
            label="Banner Mobile (600X230)"
            name="mobile"
            v-validate="'required'"
            v-model="mediaData.image_secondary"
            :feedback-show="errors.has('mobile')"
            :feedback-message="errors.first('mobile')"
          />
        </div>

        <div class="form-inline">
          <c-input
            class="form-control -inline"
            ref="startDateRef"
            icon="calendar"
            validation-icon="warning-circle"
            placeholder="Insira a data inicial"
            auto
            required
            label="Data inicial"
            name="start_date"
            autocomplete="off"
            mask="##/##/####"
            watch
            v-validate="'required'"
            :feedback-show="errors.has('start_date')"
            :feedback-message="errors.first('start_date')"
            :value="startDate"
            @input="startDate = $event"
            :flex="true"
            @click.native="showStartDate = true"
          />

          <c-popover
            v-if="showStartDate"
            :position="['top', 'center']"
            :target="$refs.startDateRef"
            @blur="showStartDate = false"
          >
            <c-date
              class="datepicker input"
              inline
              monday-first
              :calendar-class="'calendar'"
              :language="ptBR"
              :value="startDateUS"
              :disabled-dates="disabledStartDates"
              @input="startDate = $event"
            />
          </c-popover>

          <c-input
            class="form-control -inline -no-margin"
            ref="endDateRef"
            icon="calendar"
            validation-icon="warning-circle"
            placeholder="Insira a data final"
            auto
            required
            label="Data final"
            name="end_date"
            autocomplete="off"
            mask="##/##/####"
            watch
            v-validate="'required'"
            :feedback-show="errors.has('end_date')"
            :feedback-message="errors.first('end_date')"
            :flex="true"
            :value="endDate"
            @input="endDate = $event"
            @click.native="showEndDate = true"
          />

          <c-popover
            v-if="showEndDate"
            :position="['top', 'center']"
            :target="$refs.endDateRef"
            @blur="showEndDate = false"
          >
            <c-date
              class="datepicker input"
              inline
              monday-first
              :calendar-class="'calendar'"
              :language="ptBR"
              :value="endDateUS"
              :disabled-dates="disabledEndDates"
              @input="endDate = $event"
            />
          </c-popover>
        </div>

        <div class="form-inline">
          <c-input
            class="form-control -inline"
            required
            label="Hora inicial"
            placeholder="00:00"
            type="text"
            name="start_hour"
            v-validate="'required'"
            :mask="['##:##']"
            :flex="true"
            :feedback-show="errors.has('start_hour')"
            :feedback-message="errors.first('start_hour')"
            :value="start_hour"
            @input="v => start_hour = v"
          />

          <c-input
            class="form-control -inline"
            required
            label="Hora final"
            placeholder="00:00"
            type="text"
            name="end_hour"
            v-validate="'required'"
            :mask="['##:##']"
            :flex="true"
            :feedback-show="errors.has('end_hour')"
            :feedback-message="errors.first('end_hour')"
            :value="end_hour"
            @input="v => end_hour = v"
          />
        </div>

        <div class="form-inline">
          <c-select
            class="form-control -inline"
            form-label="Parceiros"
            track-by="id"
            display-by="name"
            name="partner"
            v-validate="'required'"
            :feedback-show="errors.has('partner')"
            :feedback-message="errors.first('partner')"
            :flex="isDesktop ? true : false"
            :items="partnersOptions"
            :value="chosenPartner"
            @input="changeOffers"
          />

          <c-select
            class="form-control -inline"
            form-label="Ofertas"
            track-by="id"
            display-by="name"
            name="campaign"
            v-validate="'required'"
            :feedback-show="errors.has('campaign')"
            :feedback-message="errors.first('campaign')"
            :flex="isDesktop ? true : false"
            :disabled="!chosenPartner"
            :items="campaigns"
            :value="chosenCampaign"
            @input="v => chosenCampaign = v"
          />
        </div>

        <div class="footer-buttons">
          <c-button
            v-if="isDesktop"
            class="buttons"
            type="button"
            size="lg"
            @click="$router.push({ name: 'comunicacao-midias' })"
          >
            Cancelar
          </c-button>

          <c-button
            class="buttons"
            primary
            icon="chevron-right"
            icon-size="sm"
            is-bold
            type="button"
            size="lg"
            :block="!isDesktop"
            :disabled="!isFormValid || loading"
            @click="saveMedia()"
          >
            Salvar mídia
          </c-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ptBR } from 'vuejs-datepicker/dist/locale'
import { mapActions, mapGetters } from 'vuex'
import {
  PARTNER_COUNT,
  GET_PARTNER_CAMPAIGNS,
  PARTNERS,
  CREATE_MEDIA,
  UPDATE_MEDIA,
  SUCCESS,
  FEEDBACK,
  COMPANY
} from '@/store/types'
import moment from 'moment'
import CDate from 'vuejs-datepicker'
import CUploadImage from '@/components/CComponents/CUploadImage.vue'
import CPopover from '@/components/CComponents/CPopover'
import BannerPreview from './BannerPreview'
import { timestampToDate } from '@/modules/validate/helpers'

const media_default = {
  name: null,
  image: null,
  image_secondary: null,
  start_date: null,
  end_date: null,
  partner_id: null,
  campaign_id: null
}

export default {
  name: 'Form',

  components: {
    CPopover,
    CDate,
    BannerPreview,
    CUploadImage
  },

  props: {
    media: Object,
    status: [Boolean, Number]
  },

  data () {
    return {
      showEndDate: false,
      showStartDate: false,
      loading: false,
      ptBR,
      chosenPartner: '',
      chosenCampaign: '',
      campaigns: [],
      filters: {
        asc: 'name',
        categories: [],
        desc: null,
        exclusives: true,
        name: '',
        status: '',
        page: 1,
        limit: 12
      },
      isLoading: true,
      mediaData: { ...media_default },
      start_hour: '',
      end_hour: '',
      img_desktop: null,
      isEdit: false
    }
  },

  computed: {
    ...mapGetters({
      partnersCount: PARTNER_COUNT,
      partners: PARTNERS,
      success: SUCCESS,
      company: COMPANY
    }),

    startDate: {
      get () {
        return this.mediaData.start_date
      },

      set (value) {
        this.mediaData.start_date = typeof value === 'object'
          ? value.toLocaleDateString('pt-BR')
          : value
      }
    },

    endDate: {
      get () {
        const date = this.mediaData.end_date
        return date ? moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY') : ''
      },

      set (value) {
        this.showEndDate = false

        this.mediaData.end_date = typeof value === 'object'
          ? value.toLocaleDateString('pt-BR')
          : value
      }
    },

    startDateUS () {
      return this.startDate
        ? moment(this.startDate, 'DD/MM/YYYY').format('MM/DD/YYYY')
        : ''
    },

    endDateUS () {
      return this.endDate
        ? moment(this.endDate, 'DD/MM/YYYY').format('MM/DD/YYYY')
        : ''
    },

    disabledStartDates () {
      return {
        from: new Date(moment(this.mediaData.end_date, 'DD/MM/YYYY').subtract(1, 'days'))
      }
    },

    disabledEndDates () {
      return {
        to: new Date(moment(this.mediaData.start_date, 'DD/MM/YYYY').add(1, 'days'))
      }
    },

    partnersOptions () {
      const options = []
      Object.values(this.partners).forEach(partner => {
        const value = JSON.parse(JSON.stringify(partner))
        if (value.name && value.name.length > 30) {
          value.name = `${value.name.substring(0, 25)}...`
        }
        options.push(partner)
      })
      return options
    },

    isFormValid () {
      const requireds = Object.keys(media_default)

      const valid = requireds.every(field => {
        if (field === 'partner_id') {
          return !!this.chosenPartner
        } else if (field === 'campaign_id') {
          return !!this.chosenCampaign
        }
        return !!this.mediaData[field]
      })

      return valid && !!this.start_hour && !!this.end_hour
    }
  },

  mounted () {
    this.setPartners()
  },

  created () {
    if (this.media === undefined || Object.keys(this.media).length <= 1) return

    this.isEdit = true

    this.mediaData = JSON.parse(JSON.stringify(this.media))

    const start = timestampToDate(this.mediaData.start_date).split(' ')
    const end = timestampToDate(this.mediaData.end_date).split(' ')

    this.mediaData = {
      ...this.mediaData,
      start_date: start[0],
      end_date: end[0]
    }

    this.start_hour = start[1]
    this.end_hour = end[1]

    this.chosenPartner = this.mediaData.partner_id
    this.changeOffers(this.chosenPartner)
    this.chosenCampaign = this.mediaData.campaign_id

    this.img_desktop = this.mediaData.image
  },

  methods: {
    ...mapActions({
      getPartnersCount: PARTNER_COUNT,
      getPartners: GET_PARTNER_CAMPAIGNS,
      createMedia: CREATE_MEDIA,
      editMedia: UPDATE_MEDIA,
      feedback: FEEDBACK
    }),

    setPartners () {
      this.isLoading = true
      this.getPartnersCount()
      this.filters.limit = this.partnersCount.exclusive_total
      this.getPartners().then(res => {
        this.isLoading = false
      })
    },

    changeOffers (value) {
      this.chosenPartner = value
      const partnersArray = Object.values(this.partners)

      if (value !== '' && partnersArray) {
        const chosen = partnersArray.find(partner => partner.id === value)
        this.chosenCampaign = ''
        if (chosen && chosen.campaigns) {
          this.campaigns = chosen.campaigns
        }
      }
    },

    changedImage (ev) {
      if (ev.name !== 'desktop') return

      this.img_desktop = ev.image
    },

    saveMedia () {
      this.loading = true
      const start = moment(this.mediaData.start_date, 'DD/MM/YYYY')
        .format('YYYY-MM-DD') +
        ' ' +
        this.start_hour + ':00'

      const end = moment(this.mediaData.end_date, 'DD/MM/YYYY')
        .format('YYYY-MM-DD') +
        ' ' +
        this.end_hour + ':00'

      const status = this.status ? 1 : 0

      const finalData = {
        ...this.mediaData,
        status,
        start_date: start,
        end_date: end,
        partner_id: this.chosenPartner,
        campaign_id: this.chosenCampaign
      }

      if (finalData.created_at) delete finalData.created_at
      if (finalData.updated_at) delete finalData.updated_at

      if (this.media.image === finalData.image) {
        delete finalData.image
      }

      if (this.media.image_secondary === finalData.image_secondary) {
        delete finalData.image_secondary
      }

      if (this.isEdit) {
        this.editMedia(finalData).then(() => {
          this.feedback({
            type: 'success',
            title: 'Sucesso',
            text: 'Banner atualizado com sucesso!'
          })
          require('@/analytics.js')
          window.dataLayer.push({
            event: 'editar-midia',
            company_name: this.company.name
          })
          this.loading = false
          this.$router.push({ name: 'comunicacao-midias' })
        }).catch((e) => {
          if (e.response && e.response.status === 422) {
            this.feedback({
              type: 'error',
              title: 'Não é possível atualizar o banner',
              text: e.response.message
            })
            this.loading = false
          }
        })
      } else {
        this.createMedia(finalData).then(() => {
          this.feedback({
            type: 'success',
            title: 'Sucesso',
            text: 'Banner cadastrado com sucesso'
          })
          require('@/analytics.js')
          window.dataLayer.push({
            event: 'criar-midia',
            company_name: this.company.name
          })
          this.loading = false
          this.$router.push({ name: 'comunicacao-midias' })
        }).catch((e) => {
          if (e.response && e.response.status === 422) {
            this.feedback({
              type: 'error',
              title: 'Não é possível criar o banner',
              text: e.response.data.message
            })
            this.loading = false
          }
        })
      }
    }
  }
}
</script>

<style lang='scss'>
@import '~@/styles/components/section-controls';

.form {
  .form-item {
    width: 100%;
    max-width: 657px;
  }

  .form-item + .form-item {
    margin-top: 30px;
  }

  .section-controls > .footer-buttons {
    display: flex;
    width: 80%;
    justify-content: flex-end;
    margin-top: 20px;

    > .buttons {
      height: 40px;
      width: 192px;

      @include mobile {
        width: 100%;
        height: 55px;
      }

      &:first-child {
        margin-right: 10px;
      }
    }
  }
}
</style>
