<template>
  <div class="new-notification">
    <step-count
      class="step-count"
      :current-step="step"
      :steps="steps"
      @step-click="handleUpdateStep('prev', $event)"
    />
    <transition name="fade">
      <div
        v-if="notificationLoading"
        class="loading-div"
      >
        <c-spinner />
      </div>
      <div
        v-else
        class="step-div"
      >
        <div class="step-title">
          <c-title
            class="title"
          >
            {{ stepsAction }} da Notificação
          </c-title>
        </div>
        <step-one
          v-if="step === 1"
          v-model="stepsData.first"
          :title="`${cancelModalMessage} da Notificação`"
          :step-value="stepsData.first"
          :partners-loading="partnersLoading"
          :partners="partners"
        />
        <step-two
          v-if="step === 2"
          v-model="stepsData.second"
          :is-edit="isEdit"
          :step-value="stepsData.second"
        />
      </div>
    </transition>

    <div
      class="footer"
    >
      <c-button
        data-cy="cancel-notification-button"
        class="button cancel-button"
        @click="openCancelModal = true"
      >
        Cancelar
      </c-button>
      <c-button
        data-cy="next-step-button"
        primary
        class="button"
        icon="chevron-right"
        :disabled="!enableNextStep"
        @click="handleUpdateStep('next')"
      >
        {{ nextStepButtonText }}
      </c-button>
    </div>

    <notification-name-modal
      :is-opened="openNotificationNameModal"
      @close="openNotificationNameModal = false"
      @confirm="notificationName => name = notificationName"
    />
    <c-confirm-modal
      :btn-props="{ primary: true }"
      cancel-text="Voltar"
      :confirm-text="`Cancelar ${cancelModalMessage}`"
      character="embarrassed"
      :title="`Tem certeza que deseja cancelar a ${cancelModalMessage} da notificação?`"
      message="Se cancelar, todas as informações já inseridas não serão processadas."
      :is-opened="openCancelModal"
      @close="openCancelModal = false"
      @confirm="confirmCancel"
    />
  </div>
</template>

<script>
import { COMPANY, FEEDBACK, NOTIFICATION_CREATION_STEP } from '@/store/types'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import StepCount from '@/components/General/StepCount'
import StepOne from './Steps/StepOne'
import StepTwo from './Steps/StepTwo'
import NotificationNameModal from './NotificationNameModal'
import CConfirmModal from '@/components/CComponents/CConfirmModal'
import moment from 'moment'
import { isValueValid, isUrl } from '@/modules/validate/validators.js'

export default {
  components: {
    StepCount,
    StepOne,
    StepTwo,
    NotificationNameModal,
    CConfirmModal
  },
  props: {
    loading: Boolean,
    notification: Object,
    notificationLoading: Boolean,
    partnersLoading: Boolean,
    partners: Array,
    isEdit: Boolean
  },
  data () {
    return {
      openNotificationNameModal: false,
      openCancelModal: false,
      steps: [
        {
          number: 1,
          text: 'Configurações'
        },
        {
          number: 2,
          text: 'Personalização'
        }
      ],
      name: '',
      imageDimensions: {
        width: 0,
        height: 0
      },
      imageLoading: false,
      stepsData: {
        first: {
          start_date: '',
          end_date: '',
          enable_campaign_config: 'no',
          partner: '',
          campaign: ''
        },
        second: {
          title: '',
          message: '',
          image: ''
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      company: COMPANY,
      step: NOTIFICATION_CREATION_STEP
    }),
    stepsAction () {
      return this.isEdit ? 'Edição' : 'Criação'
    },
    cancelModalMessage () {
      return this.isEdit ? 'edição' : 'criação'
    },
    validDates () {
      const today = moment()
      const start_date = this.stepsData.first.start_date
      const end_date = this.stepsData.first.end_date
      const formated_start_date = moment(start_date, 'YYYY-MM-DD', true)
      const formated_end_date = moment(end_date, 'YYYY-MM-DD', true)

      const isInitialValid = formated_start_date.isValid()
      const isEndValid = formated_end_date.isValid()
      const isOrderValid = formated_start_date.isSameOrBefore(formated_end_date)
      const isAfterToday = (formated_end_date.isAfter(today))
      const isEndDateFilledAndStarDateEmpty = end_date && !start_date
      return isInitialValid && isEndValid && isOrderValid && isAfterToday && !isEndDateFilledAndStarDateEmpty
    },
    imageEditValidation () {
      return isUrl(this.stepsData.second.image) && this.isEdit
    },
    notificationImageValidation () {
      if (this.imageEditValidation) return true
      return this.imageDimensions.width === 64 && this.imageDimensions.height === 64 && !this.imageLoading
    },
    enableNextStep () {
      if (this.loading) return false
      const firstStepValues = ['partner', 'campaign']

      if (this.step === 1) {
        if (this.stepsData.first.enable_campaign_config === 'yes') {
          const isAllFirstStepFieldsFilled = firstStepValues.every(key => isValueValid(this.stepsData.first[key]))
          return isAllFirstStepFieldsFilled && this.validDates
        } else {
          return this.validDates
        }
      } else {
        const titleValidation = this.lengthValidation(this.stepsData.second.title, 5, 38)
        const messageValidation = this.lengthValidation(this.stepsData.second.message, 5, 134)
        if (!isValueValid(this.stepsData.second.image)) return false
        if (!this.imageEditValidation) {
          const imageUrl = URL.createObjectURL(this.stepsData.second.image)
          this.getImageDimensions(imageUrl, (error, dimensions) => {
            this.imageDimensions = {
              width: dimensions.width,
              height: dimensions.height
            }
            return !error
          })
        }

        return titleValidation && messageValidation && this.notificationImageValidation && this.validDates
      }
    },
    nextStepButtonText () {
      if (this.step === 1) return 'continuar'
      else return 'salvar'
    }
  },
  watch: {
    notification () {
      if (this.isEdit) this.name = this.notification.name
      const firstStepValues = ['start_date', 'end_date']
      const secondStepValues = ['title', 'message', 'image']

      firstStepValues.forEach(key => {
        const timestampToBR = new Date(this.notification[key] * 1000).toLocaleDateString('pt-BR')
        this.stepsData.first[key] = moment(timestampToBR, 'DD/MM/YYYY').format('YYYY-MM-DD')
      })
      if (this.notification.resource_id) {
        this.findPartnerAndCampaign(this.notification.resource_id)
        this.stepsData.first.enable_campaign_config = 'yes'
      }
      secondStepValues.forEach(key => {
        this.stepsData.second[key] = this.notification[key]
      })
    }
  },
  created () {
    this.updateStep(1)
    if (!this.isEdit) { this.openNotificationNameModal = true }
  },
  methods: {
    ...mapMutations({
      updateStep: NOTIFICATION_CREATION_STEP
    }),
    ...mapActions({
      feedback: FEEDBACK
    }),
    getImageDimensions (imageUrl, callback) {
      this.imageLoading = true
      const img = new Image()
      img.onload = function () {
        const dimensions = {
          width: this.width,
          height: this.height
        }
        return callback(null, dimensions)
      }
      img.onerror = function () {
        return callback(new Error('Failed to load image'))
      }
      img.src = imageUrl
      this.imageLoading = false
    },
    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event,
          ...data
        }
      )
    },
    lengthValidation (value, minLength, maxLength) {
      return isValueValid(value) && (value.length >= minLength) && (value.length <= maxLength)
    },
    findPartnerAndCampaign (campaignId) {
      const findedPartner = this.partners.find(partner =>
        partner.campaigns.some(campaign => campaign.id === campaignId)
      )
      const findedCampaign = findedPartner.campaigns.find(campaign => {
        return campaign.id === campaignId
      }
      )
      if (findedPartner !== undefined && findedCampaign !== undefined) {
        this.stepsData.first.partner = findedPartner.id
        this.stepsData.first.campaign = findedCampaign.id
        return true
      }
      return false
    },
    handleUpdateStep (func, stepNumber) {
      if (func === 'next') {
        if (this.step !== 2) {
          this.handleAnalytics(`notificacao-passo${this.step}`, {
            company_name: this.company.slug
          })
          this.updateStep(this.step + 1)
        } else {
          this.submitNotification()
        }
      } else {
        if (this.step !== 1) {
          this.updateStep(stepNumber)
        }
      }
    },
    submitNotification () {
      const firstStepData = this.stepsData.first
      const isNotificationLinkedToCampaign =
        firstStepData.enable_campaign_config === 'yes' &&
        firstStepData.partner &&
        firstStepData.campaign
      const finalNotification = {
        name: this.name,
        start_date: firstStepData.start_date,
        end_date: firstStepData.end_date,
        ...(isNotificationLinkedToCampaign && { resource_id: firstStepData.campaign }),
        ...this.stepsData.second
      }
      if (this.imageEditValidation) { delete finalNotification.image }
      this.$emit('submit-notification', finalNotification)
    },
    confirmCancel () {
      this.feedback({
        type: 'warning',
        title: `${this.stepsAction} de notificação cancelada`
      })
      this.$router.push({ name: 'comunicacao-notificacoes' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';

.new-notification {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1020px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  > .step-count {
    margin-top: 30px;
  }
  > .loading-div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 560px;
  }
  > .step-div {
    width: 100%;
    height: 100%;
    display: block;

    > .step-title {
      margin-top: 40px;
      display: flex;
      height: 35px;

      > .title {
        max-width: 240px;
        margin-bottom: 30px;
      }
    }
  }

  & >.footer {
    width: 100%;
    justify-content: flex-end;
    display: flex;
    padding-top: 25px;

    & >.button {
      margin: 0 5px;
      width: 180px;
      height: 40px;
      border-radius: 30px;

      &.cancel-button {
        background: #FFFFFF;
        border: 1px solid var(--color-secondary);
      }
    }
  }
}
</style>
