<template>
  <section>
    <user-search
      has-filter
      :is-loading="isLoading"
      :term="filters.search"
      :user-count="userCount"
      @update-search="userFilter"
      @submit="submitSearch"
      :filters-by-status="filtersByStatus"
      :show-filter="showFilter"
      :manager-selected="managerSelected"
      @blur="showFilter = false"
      @show-filter="showFilter = !showFilter"
      @clear-filter="clearFilter"
      @user-filter="userFilter"
      @manager-filters="isManager"
      @apply-filter="submitSearch"
      @close="showFilter = false"
      @focusout="handleAnalytics('buscar-usuario', { company_name: company.name})"
    />
    <div class="filter-tags">
      <c-button
        v-if="filtersComputed.length > 1"
        external
        size="md"
        icon="trash"
        class="clear-all"
        @click="clearFilter"
      >
        limpar tudo
      </c-button>
      <filter-tag
        v-for="(tag, index) in filtersComputed"
        :key="index"
        :filter="tag"
        @remove-filter="removeFilter(tag.id)"
      />
    </div>
    <user-list
      :is-loading="isLoading"
      :user="currentUser"
      :users="users.users"
      :search="filters.search"
      :pagination="users"
      :paginating="paginating"
      :user-count="userCount"
      @paginate="paginate"
      @confirmation="confirmation"
      @open-details="goToUserDetails($event)"
      @bulkDeleteUsers="bulkDeleteUsers($event)"
      data-cy="user-list"
    />

    <user-modal-status
      :show-modal-status="showModalStatus"
      :status="payload.status"
      @close="showModalStatus = false"
      @cancel="showModalStatus = false"
      @change-status="__changeStatus"
    />

    <user-modal-delete
      :show-modal-delete="showModalDelete"
      :is-self="payload.id === currentUser.id"
      :is-manager="!!(payload.roles && payload.roles.includes('panel_manager'))"
      @close="showModalDelete = false"
      @cancel="showModalDelete = false"
      @delete="__deleteUser"
    />
    <changed-users
      :changed-users="changedUsers"
      @close="changedUsers = undefined"
    />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as types from '@/store/types'

import UserSearch from '@/components/User/UserSearch'
import UserList from '@/components/User/Listing/UserList'
import UserModalStatus from '@/components/User/UserModalStatus'
import UserModalDelete from '@/components/User/UserModalDelete'
import ChangedUsers from '@/components/User/Listing/bulkManageModals/ChangedUsers'
import FilterTag from '@/components/Filter/FilterTag.vue'

export default {
  components: { UserSearch, UserList, UserModalStatus, UserModalDelete, ChangedUsers, FilterTag },

  data () {
    return {
      filters: {},
      filtersByStatus: [
        { name: 'Ativo', icon: 'icon-light-bulb', status: 1, selected: false },
        { name: 'Convidado', icon: 'icon-watch-clock-time1', status: 0, selected: false },
        { name: 'Suspenso', icon: 'icon-block-delete-stop', status: 2, selected: false }
      ],
      isLoading: false,
      paginating: false,
      showFilter: false,
      managerSelected: false,
      showModalStatus: false,
      showModalDelete: false,
      changedUsers: undefined,
      payload: {}
    }
  },

  computed: {
    ...mapGetters({
      currentUser: types.CONFIG_USER,
      users: types.USERS_PAGINATION,
      userCount: types.USER_COUNT,
      company: types.COMPANY
    }),

    filtersComputed () {
      const selectedFilters = this.filtersByStatus.filter(filter => filter.selected === true).map(filter => {
        return {
          id: filter.status,
          label: filter.name
        }
      })
      return [...selectedFilters, this.managerSelected ? { id: 'manager', label: 'Gestor' } : null].filter(Boolean)
    }
  },

  mounted () {
    this.getUserCount()
    const savedFilters = JSON.parse(localStorage.getItem('userFilters'))
    if (savedFilters) {
      this.filtersByStatus.forEach(filter => {
        if (filter.status === savedFilters.type) {
          filter.selected = true
        }
      })
      if (savedFilters.manager) {
        this.managerSelected = true
        this.filters.manager = true
      }
      this.searchUsers({ ...savedFilters })
    } else {
      this.searchUsers()
    }
  },

  destroyed () {
    this.clearUsers()
  },
  methods: {
    ...mapActions({
      changeStatus: types.USER_TOGGLE_STATUS,
      getUsers: types.USERS_PAGINATION,
      searchUsers: types.USERS_SEARCH,
      getUserCount: types.USER_COUNT,
      clearUsers: types.USERS_CLEAR,
      deleteUser: types.USER_DELETE,
      bulkDeleteUsersAction: types.USERS_BULK_DELETE
    }),

    goToUserDetails (event) {
      this.$router.push({ name: 'usuarios-userId', params: event })
      this.handleAnalytics('detalhes-usuario', {
        company_name: this.company.name
      })
    },

    async paginate () {
      const page = this.users.current_page + 1

      this.paginating = true
      await this.getUsers({ page, ...this.filters })
      this.paginating = false
    },

    userFilter (property) {
      const hasType = ['0', '1', '2'].some(type => type.includes(this.filters.type))

      hasType && this.filters.type === property.type
        ? delete this.filters.type
        : this.filters = { ...this.filters, ...property }

      const changeStatus = filter => {
        filter.status === property.type
          ? filter.selected = !filter.selected
          : filter.selected = false
        return filter
      }
      this.filtersByStatus.map(changeStatus)
    },

    async clearFilter () {
      this.filters = {}
      this.filtersByStatus = this.filtersByStatus.map(filter => ({ ...filter, selected: false }))
      this.managerSelected = false
      localStorage.setItem('userFilters', JSON.stringify(this.filters))
      await this.searchUsers({ ...this.filters })
     },

    saveInLocalStorage () {
      const savedFilters = localStorage.getItem('userFilters')
      const parsedFilters = JSON.parse(savedFilters)
      this.filters = {
        ...parsedFilters,
        ...this.filters
      }
      localStorage.setItem('userFilters', JSON.stringify(this.filters))
    },

    removeFilter (filterId) {
      if (typeof filterId === 'number') {
        this.filtersByStatus.find(filter => filterId === filter.status).selected = false
        delete this.filters.type
      } else {
        this.managerSelected = false
        delete this.filters.manager
      }

      localStorage.setItem('userFilters', JSON.stringify(this.filters))
      this.searchUsers({ ...this.filters })
    },

    isManager () {
      this.managerSelected = !this.managerSelected

      this.managerSelected
        ? this.filters = { ...this.filters, manager: this.managerSelected }
        : delete this.filters.manager
    },

    async submitSearch () {
      const type = Object.values(this.filters)[0]
      const hasType = [0, 1, 2].some(v => v === type)
      if (this.filters.search || this.filters.manager || hasType) {
        this.isLoading = true
        this.saveInLocalStorage()
        await this.searchUsers({ ...this.filters })
        this.isLoading = false
      }
      this.showFilter = false
      this.handleAnalytics('filtrar-usuario', { company_name: this.company.name })
    },

    async confirmation (params) {
      this.payload = params

      const status = params.status
      const isDelete = params.hasOwnProperty('isDelete') && params.isDelete

      if (!isDelete) {
        this.showModalStatus = true
      }

      if (isDelete) this.showModalDelete = true
    },

    async __changeStatus () {
      await this.changeStatus(this.payload)
      this.showModalStatus = false
      if (this.payload.status === 1) {
        this.handleAnalytics('toggle-ativar-usuario', { company_name: this.company.name })
      } else {
        this.handleAnalytics('toggle-desativar-usuario', { company_name: this.company.name })
      }
    },

    __deleteUser () {
      this.deleteUser(this.payload)
      this.showModalDelete = false
      this.handleAnalytics('remover-usuario', { company_name: this.company.name })
    },

    async bulkDeleteUsers (payload) {
      payload.allUsers = false
      if (payload.users_id.includes('allDataSelected')) payload.allUsers = true
      const response = await this.bulkDeleteUsersAction(payload)
      this.changedUsers = {}
      this.changedUsers.changed = response.removed
      this.changedUsers.notChanged = response.notRemoved ? this.convertNotChangedArray(response.notRemoved) : []
    },

    convertNotChangedArray (users) {
      let usersString = ''
      if (users.length === 1) {
        usersString = users[0] + '.'
      } else {
        users.forEach(user => {
          if (users.indexOf(user) + 1 === users.length) {
            usersString = usersString + user + '.'
          } else {
            usersString = usersString + user + ', '
          }
        })
      }
      return usersString
    },

    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event,
          ...data
        }
      )
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~@/styles/reference';

.filter-tags {
  display: flex;
  justify-content: start;
  align-items: center;
  max-width: 1020px;
  width: 100%;
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  & > .clear-all {
    margin-right: 8px;
  }
}
</style>
