<template>
  <div class="filter-tag">
    <span class="label">{{ filter.label }}</span>
    <k-icon
      icon="close"
      class="icon"
      size="20"
      @click.native="removeFilter"
    />
  </div>
</template>

<script>
import { KIcon } from 'kaledo-components'

export default {
  name: 'FilterTag',
  components: { KIcon },
  props: {
    filter: {
      type: Object,
      required: true
    }
  },
  methods: {
    removeFilter () {
      this.$emit('remove-filter', this.filter.id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';
.filter-tag {
  min-width: 74px;
  height: 28px;
  padding: 4px 6px 4px 8px;
  border-radius: 8px;
  color: white;
  background-color: var(--color-primary);
  max-width: fit-content;
  display: flex;
  align-items: center;
  margin: 0 4px;
}

.icon {
  cursor: pointer;
  color: white;
}

.label {
  flex-grow: 1;
  text-align: center;
  font-size: 12px;
}
</style>
